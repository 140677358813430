import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faBagShopping,
  faDollarSign,
  faFileArchive,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import AsciiCat from "./AsciiCat";
import "./css/Sidebar.css";
import logo from "../assets/no-background.jpg";

function Sidebar({ handleLogout }) {
  const closeTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  return (
    <>
      <div className="sidebar">
        <div className="logo-sidebar">
          <a href="/">
            <img src={logo} alt="Logo" />
          </a>
        </div>
        <ul>
          <li className="sidebar-item">
            <div className="icon-column">
              <Link to="/">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
                    <lord-icon
                      src="https://cdn.lordicon.com/wmwqvixz.json"
                      trigger="morph"
                      state="morph-roll-to-back"
                      colors="primary:#ffffff"
                      style="width:25px;height:25px">
                    </lord-icon>
                  `,
                  }}
                />
              </Link>
            </div>
            <div className="text-column">
              <Link to="/">Home</Link>
            </div>
          </li>
          <li className="sidebar-item">
            <div className="icon-column">
              <Link to="/Transactions">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
                    <lord-icon
                      src="https://cdn.lordicon.com/mqdkoaef.json"
                      trigger="morph"
                      state="morph-roll-to-back"
                      colors="primary:#ffffff"
                      style="width:25px;height:25px">
                    </lord-icon>
                  `,
                  }}
                />
              </Link>
            </div>
            <div className="text-column">
              <Link to="/Transactions">Shopify</Link>
            </div>
          </li>
          <li className="sidebar-item">
            <div className="icon-column">
              <Link to="/paypal-transactions">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
                    <lord-icon
                      src="https://cdn.lordicon.com/gjjvytyq.json"
                      trigger="morph"
                      state="morph-roll-to-back"
                      colors="primary:#ffffff"
                      style="width:25px;height:25px">
                    </lord-icon>
                  `,
                  }}
                />
              </Link>
            </div>
            <div className="text-column">
              <Link to="/paypal-transactions">PayPal</Link>
            </div>
          </li>
          <li className="sidebar-item">
            <div className="icon-column">
              <Link to="/Payload">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
                    <lord-icon
                      src="https://cdn.lordicon.com/fowixcuo.json"
                      trigger="morph"
                      state="morph-roll-to-back"
                      colors="primary:#ffffff"
                      style="width:25px;height:25px">
                    </lord-icon>
                  `,
                  }}
                />
              </Link>
            </div>
            <div className="text-column">
              <Link to="/Payload">Payload</Link>
            </div>
          </li>
          <li className="sidebar-item list-bottom">
            <div className="icon-column">
              <div
                dangerouslySetInnerHTML={{
                  __html: `
                    <lord-icon
                      src="https://cdn.lordicon.com/ncseweia.json"
                      trigger="morph"
                      state="morph-roll-to-back"
                      colors="primary:#333,secondary:#333,tertiary:#333,quaternary:#333,quinary:#333,senary:#ffffff"
                      style="width:25px;height:25px">
                    </lord-icon>
                  `,
                }}
              />
            </div>
            <div className="text-column">
              <a href="#" onClick={handleLogout}>
                Sair
              </a>
            </div>
          </li>
        </ul>
      </div>
      <AsciiCat />
    </>
  );
}

export default Sidebar;
