import React from "react";
import "./css/Home.css";
import logo from "../assets/no-background.jpg";

function Home() {
  return (
    <div className="home-container">
      <header className="home-header">
        <a href="/" className="logo-link">
          <img src={logo} alt="Logo" className="logo" />
        </a>
      </header>
      <main className="home-main">
        <h1>Bem Vindo ao Site Integrador Nannacay Dapic.</h1>
      </main>
    </div>
  );
}

export default Home;
