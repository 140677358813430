import React, { useState, useEffect } from "react";
import "./css/Transactions.css";

function Transactions() {
  const [tenderTransactions, setTenderTransactions] = useState([]);
  const [orders, setOrders] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [customerDetails, setCustomerDetails] = useState({});

  const apiKey = process.env.REACT_APP_SHOPIFY_API_KEY;
  const apiSecret = process.env.REACT_APP_SHOPIFY_API_SECRET;
  const shopifyAccessToken = process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN;

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const fields = [
          "id",
          "name",
          "cancel_reason",
          "total_price",
          "total_discounts_set",
          "currency",
          "payment_gateway_names",
          "client_details",
          "billing_address",
          "customer",
          "shipping_address",
          "shipping_lines",
          "created_at",
          "transactions",
          "refunds",
          "line_items",
        ].join(",");

        const url = `/api/shopify/admin/api/2024-07/orders.json?fields=${fields}&status=any`;

        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            "X-Shopify-Access-Token": shopifyAccessToken,
          },
        });

        if (!response.ok) {
          if (!response.ok) {
            console.error(`Failed to fetch from URL: ${url}`);
            console.error(`Status: ${response.status}`);
            console.error(`Response Text: ${await response.text()}`);
            throw new Error(`Failed to fetch orders: ${response.statusText}`);
          }
        }

        const responseText = await response.text();
        try {
          const data = JSON.parse(responseText);
          setOrders(data.orders);

          const ids = data.orders.map((order) => order.customer.id);
          setCustomerDetails(Object.fromEntries(ids.map((id) => [id, {}])));
        } catch (jsonError) {
          console.error("Error parsing JSON:", jsonError);
          /*           console.log("Response Text:", responseText);
          console.log("URL requirement: ", url); */
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    const fetchTransactions = async () => {
      try {
        const response = await fetch(
          "/api/shopify/admin/api/2024-07/tender_transactions.json",
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Access-Token": shopifyAccessToken,
            },
          }
        );

        if (!response.ok) {
          console.error(
            `Failed to fetch transactions from URL: /api/shopify/admin/api/2024-07/tender_transactions.json`
          );
          console.error(`Status: ${response.status}`);
          console.error(`Response Text: ${await response.text()}`);
          throw new Error("Failed to fetch tender transactions");
        }

        const data = await response.json();
        setTenderTransactions(data.tender_transactions);
      } catch (error) {
        console.error("Error fetching tender transactions:", error);
      }
    };

    fetchOrders();
    fetchTransactions();
  }, []);

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const uniqueCustomerIds = Object.keys(customerDetails).filter(
          (customerId) => Object.keys(customerDetails[customerId]).length === 0
        );

        if (uniqueCustomerIds.length === 0) {
          return;
        }

        const idsParam = uniqueCustomerIds.join(",");
        const customerUrl = `/api/shopify/admin/api/2024-07/customers.json?ids=${idsParam}`;

        const response = await fetch(customerUrl, {
          headers: {
            "X-Shopify-Access-Token": shopifyAccessToken,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch customer details");
        }

        const responseText = await response.text();
        try {
          const data = JSON.parse(responseText);

          const updatedCustomerDetails = { ...customerDetails };
          data.customers.forEach((customer) => {
            updatedCustomerDetails[customer.id] = customer;
          });
          setCustomerDetails(updatedCustomerDetails);
        } catch (jsonError) {
          console.error("Error parsing JSON:", jsonError);
          /*           console.log("Response Text:", responseText); */
        }
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    };

    fetchCustomerDetails();
  }, [customerDetails]);

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const toggleRow = (orderId) => {
    const newExpandedRows = expandedRows.includes(orderId)
      ? expandedRows.filter((id) => id !== orderId)
      : [...expandedRows, orderId];
    setExpandedRows(newExpandedRows);
  };

  /*   console.log(orders); */

  return (
    <div className="transactions">
      <h2>Pedidos</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Quantidade</th>
            <th>Moeda</th>
            <th>Método de Pagamento</th>
            <th>Realizada em</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <>
              <tr key={order.id} onClick={() => toggleRow(order.id)}>
                <td>{order.id}</td>
                <td>{order.name}</td>
                <td>{order.total_price}</td>
                <td>{order.currency}</td>
                <td>{order.payment_gateway_names.join(", ")}</td>
                <td>{formatDate(order.created_at)}</td>
              </tr>
              {expandedRows.includes(order.id) && (
                <>
                  <tr key={`${order.id}-details`}>
                    <td colSpan="6">
                      <table className="nested-table">
                        <thead>
                          <tr>
                            <th>Product ID</th>
                            <th>Item</th>
                            <th>Vendor</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{order.line_items[0].product_id}</td>
                            <td>{order.line_items[0].name}</td>
                            <td>{order.line_items[0].vendor}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </>
              )}
            </>
          ))}
        </tbody>
      </table>
      <br />
      <h2>Transações</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>ID do Pedido</th>
            <th>Total</th>
            <th>Moeda</th>
            <th>Método de Pagamento</th>
            <th>Processada em</th>
          </tr>
        </thead>
        <tbody>
          {tenderTransactions.map((transaction) => (
            <tr key={transaction.id}>
              <td>{transaction.id}</td>
              <td>{transaction.order_id}</td>
              <td>${parseFloat(transaction.amount).toFixed(2)}</td>
              <td>{transaction.currency}</td>
              <td>{transaction.payment_method}</td>
              <td>{formatDate(transaction.processed_at)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
    </div>
  );
}

export default Transactions;
