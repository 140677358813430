import React, { useState } from "react";
import axios from "axios";
import "./css/Login.css";
import { mdiEyeOutline, mdiEyeClosed } from "@mdi/js";
import Icon from "@mdi/react";
import logo from "../assets/no-background.jpg";

const Login = ({ onLoginSuccess }) => {
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const sanitizedLogin = login;

    try {
      const response = await axios.get(
        "https://baserow.tdzain.io/api/database/rows/table/639/",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token D7Y1xo60Vu8PrAQLwkcLmsVVV1RWLRz5",
          },
        }
      );

      const users = response.data.results;
      const user = users.find(
        (user) =>
          user.field_6097 === sanitizedLogin && user.field_6098 === senha
      );

      if (user) {
        onLoginSuccess(sanitizedLogin);
      } else {
        setError("Invalid login or senha.");
      }
    } catch (error) {
      console.error("Error fetching data from Baserow:", error);
      setError("Server error. Please try again later.");
    }
  };

  return (
    <div className="login-container">
      <a href="/">
        <img src={logo} alt="Logo" />
      </a>
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Login</h2>
        {error && <p className="error-message">{error}</p>}
        <div className="form-group">
          <label htmlFor="login">Login:</label>
          <input
            type="text"
            id="login"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            required
            className="input-field"
            placeholder="Insira o seu login"
          />
        </div>
        <div className="form-group">
          <label htmlFor="senha">Senha:</label>
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"}
              id="senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              required
              className="input-field"
              placeholder="Insira a sua senha"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="toggle-password"
            >
              {showPassword ? (
                <Icon path={mdiEyeClosed} size={1} />
              ) : (
                <Icon path={mdiEyeOutline} size={1} />
              )}
            </button>
          </div>
        </div>
        <button type="submit" className="login-button">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
