import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./css/PaypalTransactions.css";

function PaypalTransactions() {
  const [paypalTransactions, setPaypalTransactions] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [expandedRows, setExpandedRows] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searched, setSearched] = useState(false);

  const paypalUsername = process.env.REACT_APP_PAYPAL_USERNAME;
  const paypalPassword = process.env.REACT_APP_PAYPAL_PASSWORD;

  const [transactionEventCodes] = useState({
    T0000:
      "Geral: recebimento de pagamento de um tipo não pertencente às outras categorias T00nn.",
    T0001: "Pagamento MassPay.",
    T0002: "Pagamento de assinatura.",
    T0003: "Pagamento pré-aprovado.",
    T0004: "Pagamento de leilão eBay.",
    T0005: "API de pagamento direto.",
    T0006: "APIs do PayPal Checkout.",
    T0007: "Pagamento padrão de pagamentos no site.",
    T0008: "Pagamento de postagem para transportadora.",
    T0009:
      "Pagamento de certificado de presente. Compra de certificado de presente.",
    T0010: "Pagamento de leilão de terceiros.",
    T0011: "Pagamento móvel.",
    T0012: "Pagamento de terminal virtual.",
    T0013: "Pagamento de doação.",
    T0014: "Pagamentos de reembolso.",
    T0015: "Pagamento de terceiros.",
    T0016: "Recuperação de terceiros.",
    T0017: "Transferências de loja para loja.",
    T0018: "Pagamento PayPal Here.",
    T0019: "Pagamento genérico financiado por instrumento.",
    T0021: "Pagamento com criptomoeda.",
    T0100: "Taxa geral de não pagamento.",
    T0101: "Pagamentos no site.",
    T0102: "Taxa de retirada de banco estrangeiro.",
    T0103: "Taxa de retirada de cheque WorldLink.",
    T0104: "Taxa de lote de pagamento em massa.",
    T0105: "Retirada de cheque.",
    T0106: "Taxa de processamento de estorno.",
    T0107: "Taxa de pagamento.",
    T0108: "Retirada de caixa eletrônico.",
    T0109: "Varredura automática da conta.",
    T0110: "Retirada de cartão de crédito internacional.",
    T0111: "Taxa de garantia para compra de garantia.",
    T0112: "Taxa de expiração de certificado de presente.",
    T0113: "Taxa de parceiro.",
    T0114: "Taxa de disputa.",
    T0200: "Conversão de moeda geral.",
    T0201: "Conversão de moeda iniciada pelo usuário.",
    T0202: "Conversão de moeda necessária para cobrir saldo negativo.",
    T0300: "Financiamento geral da conta PayPal.",
    T0301: "Financiamento do gerenciador de saldo PayPal da conta PayPal.",
    T0302: "Financiamento ACH para recuperação de fundos do saldo da conta.",
    T0303: "Transferência eletrônica de fundos (EFT) (banco alemão).",
    T0400: "Retirada geral da conta PayPal.",
    T0401: "Varredura Automática.",
    T0403: "Retiradas iniciadas manualmente pelo usuário.",
    T0500: "Transação geral com cartão de débito PayPal.",
    T0501: "Transação virtual com cartão de débito PayPal.",
    T0502: "Retirada de cartão de débito PayPal para caixa eletrônico.",
    T0503: "Transação oculta com cartão de débito virtual PayPal.",
    T0504: "Adiantamento em dinheiro com cartão de débito PayPal.",
    T0505: "Autorização de débito PayPal.",
    T0600: "Retirada geral de cartão de crédito.",
    T0700: "Depósito geral de cartão de crédito.",
    T0800: "Bônus geral de um tipo.",
    T0801: "Bônus de reembolso de cartão de débito.",
    T0802: "Bônus de conta de referência de comerciante.",
    T0803: "Bônus de conta de gerenciador de saldo.",
    T0804: "Bônus de garantia do comprador PayPal.",
    T0805: "Bônus de proteção PayPal.",
    T0806: "Bônus para o primeiro uso do ACH.",
    T0807: "Reembolso de taxa de segurança de cartão de crédito.",
    T0808: "Bônus de reembolso de cartão de crédito.",
    T0900: "Resgate geral de incentivo ou certificado.",
    T0901: "Resgate de certificado de presente.",
    T0902: "Resgate de incentivo de pontos.",
    T0903: "Resgate de cupom.",
    T0904: "Incentivo de fidelidade eBay.",
    T1000: "Transação de pagamento de contas.",
    T1100: "Reversão geral de um tipo.",
    T1101: "Reversão de transação de retirada ACH.",
    T1102: "Reversão de transação de cartão de débito.",
    T1103: "Reversão de uso de pontos.",
    T1104: "Reversão de depósito ACH.",
    T1105: "Reversão de retenção geral da conta.",
    T1106: "Reversão de pagamento, iniciada pelo PayPal.",
    T1107: "Reembolso de pagamento, iniciado pelo comerciante.",
    T1108: "Reversão de taxa.",
    T1109: "Reembolso de taxa.",
    T1110: "Retenção para investigação de disputa (T15nn).",
    T1111: "Cancelamento de retenção para resolução de disputa.",
    T1112: "Reversão MAM.",
    T1113: "Pagamento de crédito sem referência.",
    T1114: "Transação de reversão MassPay.",
    T1115: "Transação de reembolso MassPay.",
    T1116: "Reversão de revisão de pagamento instantâneo (IPR).",
    T1117: "Reversão de reembolso ou reembolso em dinheiro.",
    T1118:
      "Reversões genéricas de instrumento/carteira aberta (lado do vendedor).",
    T1119:
      "Reversões genéricas de instrumento/carteira aberta (lado do comprador).",
    T1200: "Ajuste geral da conta.",
    T1201: "Estorno.",
    T1202: "Reversão de estorno.",
    T1203: "Ajuste de cobrança.",
    T1204: "Ajuste de incentivo.",
    T1205: "Reembolso de estorno.",
    T1207: "Rejeição de representação de estorno.",
    T1208: "Cancelamento de estorno.",
    T1300: "Autorização geral.",
    T1301: "Reautorização.",
    T1302: "Anulação de autorização.",
    T1400: "Dividendo geral.",
    T1500:
      "Retenção temporária geral de um tipo não pertencente às outras categorias T15nn.",
    T1501: "Retenção de conta para autorização aberta.",
    T1502: "Retenção de conta para depósito ACH.",
    T1503: "Retenção temporária no saldo disponível.",
    T1600:
      "Financiamento de pagamento de crédito do comprador PayPal. Deve ter se inscrito para crédito do comprador.",
    T1601: "Crédito BML. Transferência de BML.",
    T1602: "Pagamento de crédito do comprador.",
    T1603:
      "Retirada de pagamento de crédito do comprador. Transferência para BML.",
    T1700: "Retirada geral para instituição não bancária.",
    T1701: "Retirada WorldLink.",
    T1800:
      "Pagamento de crédito geral do comprador. Deve ter se inscrito para crédito do comprador.",
    T1801: "Retirada BML. Transferência para BML.",
    T1900: "Ajuste geral sem evento relacionado a negócios.",
    T2000: "Transferência geral intra-conta.",
    T2001: "Consolidação de liquidação.",
    T2002: "Transferência de fundos de contas a pagar.",
    T2003: "Transferência para entidade GL externa.",
    T2004: "Financiamento de contas a receber.",
    T2101: "Retenção geral.",
    T2102: "Liberação de retenção geral.",
    T2103: "Retenção de reserva.",
    T2104: "Liberação de reserva.",
    T2105: "Retenção de revisão de pagamento.",
    T2106: "Liberação de revisão de pagamento.",
    T2107: "Retenção de pagamento.",
    T2108: "Liberação de retenção de pagamento.",
    T2109: "Compra de certificado de presente.",
  });

  useEffect(() => {
    let url = "/api/paypal/v1/oauth2/token"; // live server
    let username = paypalUsername;
    let password = paypalPassword;

    const base64encodedData = btoa(`${username}:${password}`); // Base64 encoding

    const fetchAccessToken = async () => {
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Basic ${base64encodedData}`,
          },
          body: "grant_type=client_credentials",
        });

        if (!response.ok) {
          throw new Error("Failed to obtain access token");
        }

        const data = await response.json();
        setAccessToken(data.access_token);
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };

    fetchAccessToken();
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    setEndDate(currentDate);

    const endDate = new Date();
    endDate.setDate(currentDate.getDate() - 30);
    setStartDate(endDate);
  }, []);

  const fetchPayPalData = async () => {
    if (!startDate || !endDate) {
      console.error("Start date and end date are required");
      return;
    }

    const fields = [
      "transaction_info",
      "cart_info",
      "payer_info",
      "shipping_info",
      "auction_info",
      "incentive_info",
      "store_info",
    ].join(",");

    const startDateISOString = new Date(startDate).toISOString();
    const endDateISOString = new Date(endDate).toISOString();

    const url = `/api/paypal/v1/reporting/transactions?start_date=${startDateISOString}&end_date=${endDateISOString}&fields=${fields}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch PayPal data");
      }

      const data = await response.json();
      const reversedTransactions = data.transaction_details.reverse();
      setPaypalTransactions(reversedTransactions);
      setSearched(true);

      localStorage.setItem(
        "paypalTransactions",
        JSON.stringify(reversedTransactions)
      );
      window.location.reload();
    } catch (error) {
      console.error("Error fetching PayPal data:", error);
    }
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const toggleRow = (transactionId) => {
    const newExpandedRows = expandedRows.includes(transactionId)
      ? expandedRows.filter((id) => id !== transactionId)
      : [...expandedRows, transactionId];
    setExpandedRows(newExpandedRows);
  };

  useEffect(() => {
    const storedTransactions = JSON.parse(
      localStorage.getItem("paypalTransactions")
    );
    if (storedTransactions) {
      const reversedStoredTransactions = storedTransactions.reverse();
      setPaypalTransactions(reversedStoredTransactions);
      setSearched(true);
    }
  }, []);

  const buildTransactionHierarchy = (transactions) => {
    const transactionMap = {};
    const rootTransactions = [];

    transactions.forEach((transaction) => {
      const { transaction_id, paypal_reference_id } =
        transaction.transaction_info;
      transaction.children = [];
      transactionMap[transaction_id] = transaction;

      if (paypal_reference_id && transactionMap[paypal_reference_id]) {
        if (!transactionMap[paypal_reference_id].children) {
          transactionMap[paypal_reference_id].children = [];
        }
        transactionMap[paypal_reference_id].children.push(transaction);
      } else {
        rootTransactions.push(transaction);
      }
    });

    return rootTransactions;
  };

  const renderTransactionRow = (transaction, level = 0) => {
    const {
      transaction_id,
      transaction_amount,
      fee_amount,
      transaction_initiation_date,
      transaction_event_code,
    } = transaction.transaction_info;

    return (
      <React.Fragment key={transaction_id}>
        <tr onClick={() => toggleRow(transaction_id)}>
          <td style={{ paddingLeft: `${level * 20 + 20}px` }}>
            {transaction_id}
          </td>
          <td>
            {transaction_amount ? transaction_amount.currency_code : "N/A"}
          </td>
          <td>
            {transaction_amount
              ? parseFloat(transaction_amount.value).toFixed(2)
              : parseFloat(0.0).toFixed(2)}
          </td>
          <td>
            {fee_amount
              ? parseFloat(fee_amount.value).toFixed(2)
              : parseFloat(0.0).toFixed(2)}
          </td>
          <td>
            {transactionEventCodes[transaction_event_code] ||
              "Unknown Event Code"}
          </td>
          <td>{formatDate(transaction_initiation_date)}</td>
        </tr>
        {expandedRows.includes(transaction_id) &&
          transaction.children.map((child) =>
            renderTransactionRow(child, level + 1)
          )}
      </React.Fragment>
    );
  };

  const hierarchicalTransactions =
    buildTransactionHierarchy(paypalTransactions);

  /* console.log(paypalTransactions); */

  return (
    <div className="paypalTransactions">
      <h2>Transações</h2>
      <div className="date-picker-container">
        <div>
          <label>Data Inicial:</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="dd-MM-yyyy"
            className="date-picker"
            placeholderText="Data Inicial"
          />
        </div>
        <div>
          <label>Data Final:</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="dd-MM-yyyy"
            className="date-picker"
            placeholderText="Select end date"
          />
        </div>
        <button onClick={fetchPayPalData}>
          Pesquisar
        </button>
      </div>
      {searched && (
        <>
          {paypalTransactions.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Moeda</th>
                  <th>Valor</th>
                  <th>Taxas</th>
                  <th>Transação</th>
                  <th>Criada em</th>
                </tr>
              </thead>
              <tbody>
                {hierarchicalTransactions.map((transaction) =>
                  renderTransactionRow(transaction)
                )}
              </tbody>
            </table>
          ) : (
            <p className="no-transactions">No transactions found</p>
          )}
        </>
      )}
    </div>
  );
}

export default PaypalTransactions;
