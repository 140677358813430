import React, { useState, useEffect } from "react";
import "./css/Payload.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "react-query";

export default function Payload() {
  const [paypalTransactions, setPaypalTransactions] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [orders, setOrders] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDate, setEndDate] = useState(new Date()); // today
  const [accessTokenDapic, setAccessTokenDapic] = useState([]);

  const shopifyAccessToken = process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN;

  const paypalUsername = process.env.REACT_APP_PAYPAL_USERNAME;
  const paypalPassword = process.env.REACT_APP_PAYPAL_PASSWORD;

  const dapicCompany = process.env.REACT_APP_DAPIC_COMPANY;
  const dapicToken = process.env.REACT_APP_DAPIC_TOKEN;

  const dapicURL = "/api/dapic/v1/pedidosvendassimples";

  useEffect(() => {
    const fetchAccessToken = async () => {
      const url = "/api/paypal/v1/oauth2/token"; // live server

      const base64encodedData = btoa(`${paypalUsername}:${paypalPassword}`); // Base64 encoding

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Basic ${base64encodedData}`,
          },
          body: "grant_type=client_credentials",
        });

        if (!response.ok) {
          throw new Error("Failed to obtain access token");
        }

        const data = await response.json();
        setAccessToken(data.access_token);
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };

    fetchAccessToken();
  }, []);

  useEffect(() => {
    const fetchPayPalData = async () => {
      if (!startDate || !endDate) {
        console.error("Start date and end date are required");
        return;
      }

      const fields = [
        "transaction_info",
        "cart_info",
        "payer_info",
        "shipping_info",
        "auction_info",
        "incentive_info",
        "store_info",
      ].join(",");

      const startDateISOString = new Date(startDate).toISOString();
      const endDateISOString = new Date(endDate).toISOString();

      const url = `/api/paypal/v1/reporting/transactions?start_date=${startDateISOString}&end_date=${endDateISOString}&fields=${fields}`;

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch PayPal data");
        }

        const data = await response.json();
        const transactionsPaypal = data.transaction_details;

        const cleanedTransactions = transactionsPaypal.map((transaction) => {
          const { transaction_info } = transaction;
          if (transaction_info && transaction_info.invoice_id) {
            const { invoice_id } = transaction_info;
            const cleanedInvoiceId = invoice_id.split(".")[0];
            return { ...transaction, cleanedInvoiceId };
          }

          return transaction;
        });

        setPaypalTransactions(cleanedTransactions);
      } catch (error) {
        console.error("Error fetching PayPal data:", error);
      }
    };

    if (accessToken && startDate && endDate) {
      fetchPayPalData();
    }
  }, [accessToken, startDate, endDate]);

  const buildTransactionHierarchy = (transactions) => {
    const transactionMap = {};
    const rootTransactions = [];

    transactions.forEach((transaction) => {
      const { transaction_id, paypal_reference_id } =
        transaction.transaction_info;
      transaction.children = [];

      transactionMap[transaction_id] = transaction;

      if (paypal_reference_id && transactionMap[paypal_reference_id]) {
        transactionMap[paypal_reference_id].children.push(transaction);
      } else {
        rootTransactions.push(transaction);
      }
    });

    return rootTransactions;
  };

  const excludeTransactionsWithCodeT1106 = (transactions) => {
    const shouldExcludeTransaction = (transaction) => {
      if (transaction.transaction_info.transaction_event_code === "T1106") {
        return true;
      }

      let shouldExclude = false;
      transaction.children = transaction.children.filter((child) => {
        if (shouldExcludeTransaction(child)) {
          shouldExclude = true;
          return false;
        }
        return true;
      });

      return shouldExclude;
    };

    return transactions.filter(
      (transaction) => !shouldExcludeTransaction(transaction)
    );
  };

  const hierarchicalTransactions = excludeTransactionsWithCodeT1106(
    buildTransactionHierarchy(paypalTransactions)
  );

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const url = `/api/shopify/admin/api/2024-07/orders.json?status=any`;

        const response = await fetch(url, {
          headers: {
            "X-Shopify-Access-Token": shopifyAccessToken,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch orders");
        }

        const data = await response.json();
        const ordersWithBarcode = await Promise.all(
          data.orders.map(async (order) => {
            const lineItemsWithBarcode = await Promise.all(
              order.line_items.map(async (item) => {
                try {
                  const variantResponse = await fetch(
                    `/api/shopify/admin/api/2024-07/variants/${item.variant_id}.json`,
                    {
                      headers: {
                        "X-Shopify-Access-Token": shopifyAccessToken,
                      },
                    }
                  );

                  if (!variantResponse.ok) {
                    throw new Error(
                      `Failed to fetch variant ${item.variant_id}`
                    );
                  }

                  const variantData = await variantResponse.json();
                  return {
                    ...item,
                    barcode: variantData.variant.barcode,
                  };
                } catch (error) {
                  console.error(
                    `Error fetching variant ${item.variant_id} data:`,
                    error
                  );
                  return item;
                }
              })
            );

            return {
              ...order,
              line_items: lineItemsWithBarcode,
            };
          })
        );

        setOrders(ordersWithBarcode);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getLastChildValue = (transaction) => {
    if (!transaction.children || transaction.children.length === 0) {
      return transaction;
    }

    return getLastChildValue(
      transaction.children[transaction.children.length - 1]
    );
  };

  const transformData = (shopifyOrder, hierarchicalTransactions) => {
    const lastChildTransaction = getLastChildValue(hierarchicalTransactions);

    const wholeAddress =
      hierarchicalTransactions?.shipping_info?.address?.line1 || "N/A";
    const addressNumber = wholeAddress.match(/\d+/g)?.[0] || "N/A";
    const addressStreetArray = wholeAddress.match(/[a-zA-Z]+/g) || ["N/A"];
    const addressStreet = addressStreetArray.join(" ") || "N/A";

    const paidPrice = parseFloat(
      lastChildTransaction?.transaction_info?.transaction_amount?.value
    ).toFixed(2);
    const formattedPaidPrice = Math.floor(paidPrice * 100) / 100;

    const ValorFrete = parseFloat((0).toFixed(2));
    const Desconto = parseFloat((0).toFixed(2));
    const Acrescimo = parseFloat((0).toFixed(2));

    function formatToTwoDecimalPlaces(value) {
      return parseFloat(value.toFixed(2));
    }

    const produtos = shopifyOrder?.line_items.map((item) => ({
      Codigo: item.barcode.toString() || "Não Informado",
      Quantidade: item.quantity,
      Valor: formattedPaidPrice,
    }));

    return {
      Codigo: shopifyOrder.id.toString() || "Não Informado",
      IdConfiguracaoWebService: 5,
      IdFuncionario: 10,
      Data:
        formatDate(
          hierarchicalTransactions?.transaction_info
            ?.transaction_initiation_date
        ) || "Não Informado",
      ValorFrete: formatToTwoDecimalPlaces(ValorFrete),
      ServicoFrete: "Não Informado",
      Desconto: formatToTwoDecimalPlaces(Desconto),
      Acrescimo: formatToTwoDecimalPlaces(Acrescimo),
      Cliente: {
        RazaoSocial:
          hierarchicalTransactions?.payer_info?.payer_name
            ?.alternate_full_name || "Não Informado",
        Fantasia:
          hierarchicalTransactions?.payer_info?.payer_name
            ?.alternate_full_name || "Não Informado",
        TipoPessoa: 1,
        /* CpfCnpj: shopifyOrder?.customer?.id.toString() || "Não Informado", */
        CpfCnpj: "",
        /* RgIe: shopifyOrder?.customer?.id.toString() || "Não Informado", */
        RgIe: hierarchicalTransactions?.payer_info?.email_address,
        Telefone: shopifyOrder?.phone?.toString() || "Não Informado",
        Email:
          hierarchicalTransactions?.payer_info?.email_address ||
          "Não Informado",
      },
      Endereco: {
        Logradouro: addressStreet || "Não Informado",
        Numero: addressNumber || "Não Informado",
        Complemento:
          hierarchicalTransactions?.shipping_info?.address?.line2 ||
          "Não Informado",
        Bairro: "Não Informado",
        Cidade:
          hierarchicalTransactions?.shipping_info?.address?.city ||
          "Não Informado",
        Estado: "EX",
        /* hierarchicalTransactions?.shipping_info?.address?.state ||
          "Não Informado", */
        Cep:
          hierarchicalTransactions?.shipping_info?.address?.postal_code ||
          "Não Informado",
      },
      Pagamentos: [
        {
          FormaPagamento:
            shopifyOrder?.payment_gateway_names[0] || "Não Informado",
          Parcelas: 1,
          ValorPago: formattedPaidPrice,
          DataPagamento:
            formatDate(
              lastChildTransaction?.transaction_info
                ?.transaction_initiation_date
            ) || "Não Informado",
        },
      ],
      Produtos: produtos,
    };
  };

  const combinedData = orders.reduce((accumulator, order) => {
    const orderItemName = order.line_items?.[0]?.name?.toLowerCase();
    const orderDate = new Date(order.created_at);
    const orderDateString = !isNaN(orderDate)
      ? orderDate.toISOString().split("T")[0]
      : null;

    const matchingPaypalTransaction = hierarchicalTransactions.find((tx) => {
      const itemDetails = tx.cart_info?.item_details?.[0];
      const transactionItemName = itemDetails?.item_name?.toLowerCase();
      const transactionDateValue =
        tx.transaction_info?.transaction_initiation_date;

      let transactionDateString = null;
      if (transactionDateValue) {
        const transactionDate = new Date(transactionDateValue);
        transactionDateString = !isNaN(transactionDate)
          ? transactionDate.toISOString().split("T")[0]
          : null;
      } else {
        //console.log("Transaction Date Value is missing or undefined.");
      }

      /* console.log("---");
      console.log("Order Date String:", orderDateString);
      console.log("Order Item Name:", orderItemName);
      console.log("Transaction Date Value:", transactionDateValue);
      console.log("Transaction Date String:", transactionDateString);
      console.log("Transaction Item Name:", transactionItemName); */

      const matchByInvoiceId = tx.cleanedInvoiceId === order.name;
      const matchByDetailsAndDate =
        orderItemName === transactionItemName &&
        orderDateString === transactionDateString;

      /* console.log("Match by Invoice ID:", matchByInvoiceId);
      console.log("Match by Details and Date:", matchByDetailsAndDate); */

      return matchByInvoiceId || matchByDetailsAndDate;
    });

    if (matchingPaypalTransaction) {
      //console.log("Matching Transaction Found:", matchingPaypalTransaction);
      accumulator.push(transformData(order, matchingPaypalTransaction));
    } else {
      //console.log("No Matching Transaction Found for Order:", order);
    }
    //console.log("---");

    return accumulator;
  }, []);

  const combinedDataStringJSON = JSON.stringify(combinedData);

  //console.log("combinedData: ", combinedDataStringJSON);

  //===============THIS IS A TEST CONFIG TO SEND THE JSON TO DAPIC!==================

  useEffect(() => {
    const fetchAccessTokenDapic = async () => {
      const url = `/api/dapic/autenticacao/v1/login?empresa=${dapicCompany}&TokenIntegracao=${dapicToken}`;

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: "grant_type=client_credentials",
        });

        if (!response.ok) {
          throw new Error("Failed to obtain access token");
        }

        const data = await response.json();
        setAccessTokenDapic(data.access_token);
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };

    fetchAccessTokenDapic();
  }, []);

  const sendSingleDataToDapic = async (dataObject) => {
    try {
      const url = dapicURL;

      if (!accessTokenDapic) {
        throw new Error("Access token not available");
      }

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenDapic}`,
        },
        body: JSON.stringify(dataObject),
      });

      if (!response.ok) {
        const errorText = await response.text(); // Capture the response body for more details
        console.error(
          `Error sending data to API: ${response.status} ${response.statusText}`
        );
        console.error("Response Body:", errorText);
        console.error("Failed Data Object:", dataObject.Codigo);
        throw new Error("Failed to send data to API");
      }

      const responseData = await response.json();
      console.log("Data sent successfully:", responseData);
    } catch (error) {
      console.error("Error sending data to API:", error.message);
      /* console.error("Failed Data Object:", JSON.stringify(dataObject, null, 2));
      console.error("Stack Trace:", error.stack); */
    }
  };

  const sendDataToDapic = async () => {
    try {
      for (const dataObject of combinedData) {
        await sendSingleDataToDapic(dataObject);
      }
    } catch (error) {
      console.error("Error sending data to API:", error);
    }
  };

  useEffect(() => {
    if (accessTokenDapic && combinedData.length > 0) {
      sendDataToDapic();
    }
  }, [accessTokenDapic, combinedData]);

  //=================================================================================

  const toggleRow = (orderId) => {
    const newExpandedRows = expandedRows.includes(orderId)
      ? expandedRows.filter((id) => id !== orderId)
      : [...expandedRows, orderId];
    setExpandedRows(newExpandedRows);
  };

  const handleUploadClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div className="transactions">
      <h2>Transactions from Shopify paid with PayPal</h2>
      <table className="transactions-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>ID do Produto</th>
            <th>Valor</th>
            <th>Criado em</th>
            <th>Upload</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => {
            const matchedData = combinedData.find(
              (data) => data.Codigo === order.id.toString()
            );
            if (!matchedData) return null;

            return (
              <React.Fragment key={matchedData.Codigo}>
                <tr
                  className={
                    expandedRows.includes(matchedData.Codigo)
                      ? "expanded-row"
                      : ""
                  }
                  onClick={() => toggleRow(matchedData.Codigo)}
                >
                  <td>{matchedData.Codigo}</td>
                  <td>
                    {matchedData.Produtos.length > 0
                      ? matchedData.Produtos[0].Codigo
                      : "N/A"}
                  </td>
                  <td>
                    {matchedData.Pagamentos.length > 0
                      ? `R$ ${parseFloat(
                          matchedData.Pagamentos[0].ValorPago
                        ).toFixed(2)}`
                      : "N/A"}
                  </td>
                  <td>{matchedData.Data}</td>
                  <td>
                    <button
                      className="upload-button"
                      onClick={handleUploadClick}
                    >
                      <FontAwesomeIcon icon={faUpload} size={"xl"} />
                    </button>
                  </td>
                </tr>
                {expandedRows.includes(matchedData.Codigo) && (
                  <tr key={`${matchedData.Codigo}-details-client`}>
                    <td colSpan="5">
                      <table className="nested-table">
                        <thead>
                          <tr>
                            <th>Nome Cliente</th>
                            <th>Email Cliente</th>
                            <th>Endereço de Entrega</th>
                            <th>Método de Pagamento</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{matchedData.Cliente.RazaoSocial}</td>
                            <td>{matchedData.Cliente.Email}</td>
                            <td>
                              {matchedData.Endereco.Logradouro},{" "}
                              {matchedData.Endereco.Numero}
                              <br />
                              {matchedData.Endereco.Cidade},{" "}
                              {matchedData.Endereco.Estado} -{" "}
                              {matchedData.Endereco.Cep}
                            </td>
                            <td>
                              {matchedData.Pagamentos.length > 0
                                ? matchedData.Pagamentos[0].FormaPagamento
                                : "N/A"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="nested-table">
                        <thead>
                          <tr>
                            <th>Quantidade</th>
                            <th>Serviço</th>
                            <th>Discontos</th>
                            <th>Comissão</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="singleNumber">
                              {matchedData.Produtos.length}
                            </td>
                            <td>{matchedData.ServicoFrete}</td>
                            <td>{matchedData.Desconto}</td>
                            <td>{matchedData.Acrescimo}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
