import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar.js";
import Home from "./pages/Home.js";
import Transactions from "./pages/Transactions.js";
import PaypalTransactions from "./pages/PaypalTransactions.js";
import Payload from "./pages/Payload.js";
import Login from "./pages/Login.js";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );
  const [userCpfCnpj, setUserCpfCnpj] = useState(
    localStorage.getItem("userCpfCnpj")
  );

  const handleLoginSuccess = (login) => {
    setIsLoggedIn(true);
    setUserCpfCnpj(login);
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("userCpfCnpj", login);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserCpfCnpj(null);
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userCpfCnpj");
  };

  return (
    <div className="app-container">
      {isLoggedIn ? (
        <Router>
          <div className="app">
            <Sidebar handleLogout={handleLogout} />
            <div className="content">
              <Routes>
                <Route path="/" element={<Home cpfCnpj={userCpfCnpj} />} />
                <Route path="/transactions" element={<Transactions />} />
                <Route
                  path="/paypal-transactions"
                  element={<PaypalTransactions />}
                />
                <Route path="/payload" element={<Payload />} />
              </Routes>
            </div>
          </div>
        </Router>
      ) : (
        <Login onLoginSuccess={handleLoginSuccess} />
      )}
    </div>
  );
}

export default App;
